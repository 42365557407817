<template>
  <div class="auth-container">
    <div class="w-100 h-100">
      <div class="row m-2 h-100 justify-content-center">
        <div class="col-10 col-md-5 col-lg-3 align-self-center">
          <div class="w-100 d-flex justify-content-center">
            <img src="@/assets/images/logo.png" style="height: 60px" alt="" />
          </div>
          <div class="w-100 mt-3">
            <form id="auth-form" @submit.prevent="registerClicked">
              <div class="form-group mb-1">
                <input
                  type="text"
                  class="form-control"
                  v-model="models.username"
                  id="auth-username"
                  :class="{ 'border-danger': errors.username }"
                  placeholder="Enter Full Name"
                />
                <label class="form-control-label" for="username">Full Name</label>
              </div>
              <div class="form-group mb-1">
                <input
                  type="email"
                  class="form-control"
                  v-model="models.email"
                  id="auth-email"
                  :class="{ 'border-danger': errors.email }"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <label class="form-control-label" for="email">Email</label>
              </div>
              <div class="form-group mb-1">
                <input
                  type="password"
                  class="form-control"
                  v-model="models.password"
                  :class="{ 'border-danger': errors.password }"
                  id="auth-password"
                  placeholder="Password"
                />
                <label class="form-control-label" for="password"
                  >Password</label
                >
              </div>
              <div class="form-group mb-1">
                <select class="auth form-control" 
                  id="auth-position" 
                  v-model="models.position"
                  :class="{ 'border-danger': errors.position }">
                  <option :value="null" disabled>Select Position</option>
                  <option value="Manager">Manager</option>
                  <option value="Supervisor">Supervisor</option>
                  <option value="Engineer">Engineer</option>
                  <option value="Executive">Executive</option>
                </select>
                <label class="form-control-label" for="position">Position</label>
              </div>
              <button type="submit" class="btn w-100">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="isProcessing"
                ></span>
                <span v-if="!isProcessing">Register</span>
              </button>
            </form>

            <button
              type="submit"
              class="btn btn-sm btn-secondary w-100 mt-2"
              @click="goTo('Auth')"
            >
              <span>I already have an account</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      options: [
        "HSSE",
        "Project Management",
        "Engineering",
        "Construction",
        "Contract & Procurement",
        "Security",
        "QAQC",
        "Commissioning"
      ],
      models: {
        username: "",
        email: "",
        password: "",
        position: null
      },
      errors: {
        username: false,
        email: false,
        password: false,
        position: false
      },
      isProcessing: false
    };
  },
  watch: {},
  methods: {
    registerClicked() {
      console.log("signup");
      this.isProcessing = true;
      console.log(this.model);
      const hasError = this.verifyForm();
      console.log(hasError);
      if (hasError) {
        console.log("has error");
        this.isProcessing = false;
      } else {
        console.log("proceed");
        Swal.fire({
          title: "Select department",
          input: "radio",
          inputOptions: this.options,
          inputValidator: value => {
            if (!value) {
              return "You need to choose something!";
            } else {
              this.models.department = this.options[value];
              console.log(this.models);
              this.signup();
            }
          },
          willClose: () => {this.isProcessing = false}
        });
      }
    },

    signup() {
      API.post("auth/local/register", this.models)
        .then(res => {
          let user = res.data;
          console.log(user)

          API.post("profiles", {
            name: this.models.username,
            department: this.models.department,
            position: this.models.position,
            user: user.user.id
          })
            .then(() => {
              // let user = retVal.data.user;

              // if (user.profile && user.profile.isApproved) {
              //   localStorage.setItem("user", JSON.stringify(user));
              //   localStorage.setItem("token", retVal.data.jwt);

                this.$router.push({
                  name: "Auth"
                });
              // } else {
              Swal.fire(
                "Success",
                "You have successfully register, Please wait for admin approval before you can login. Thank you",
                "success"
              );
              // alert("Please wait for admin approval before you can login");
              // }

              this.isProcessing = false;
            })
            .catch(err => {
              this.isProcessing = false;
              console.error(err);
              alert("Something went wrong.");
            });
        })
        .catch(err => {
          this.isProcessing = false;
          console.log(err);
          alert("Email already register");
        });
    },

    goTo(name) {
      console.log(name);
      this.$router.replace({
        name: name
      });
    },

    verifyForm() {
      this.loginError = false;
      for (let index = 0; index < Object.keys(this.models).length; index++) {
        const key = Object.keys(this.models)[index];
        const model = this.models[key];

        if (!model) {
          this.errors[key] = true;
          this.loginError = true;
        } else {
          this.errors[key] = false;
        }
      }

      return this.loginError;
    }
  },
  mounted() {}
};
</script>
